<template>
  <div id="behaviour-list">
    <Loader v-if="isLoading" />
    <div v-show="!isLoading">
      <div style="text-align: right !important">
        <vs-button
          type="border"
          icon-pack="feather"
          icon="icon-plus-circle"
          @click="onCreateClick"
          >{{ $l.get("dictionary.create-button") }}</vs-button
        >
      </div>
      <vs-table
        :sst="true"
        @search="handleSearch"
        @sort="handleSort"
        search
        v-if="data"
        :data="data.behavioursPage.results"
      >
        <template slot="header">
          <h3>{{ $l.get("behaviour-list.table-header") }}</h3>
        </template>
        <template slot="thead">
          <vs-th sort-key="summary_pt_br" v-if="$l.isPtBr">{{
            $l.get("behaviour-list.table-summary")
          }}</vs-th>
          <vs-th sort-key="summary_en_us" v-if="$l.isEnUs">{{
            $l.get("behaviour-list.table-summary")
          }}</vs-th>
          <vs-th sort-key="summary_es_es" v-if="$l.isEsEs">{{
            $l.get("behaviour-list.table-summary")
          }}</vs-th>
          <vs-th sort-key="genoset__name">{{
            $l.get("behaviour-list.table-genoset")
          }}</vs-th>
          <vs-th sort-key="genotype__snp__rsid">{{
            $l.get("behaviour-list.table-genotype")
          }}</vs-th>
          <vs-th sort-key="magnitude">{{
            $l.get("behaviour-list.table-magnitude")
          }}</vs-th>
          <vs-th sort-key="repute">
            {{ $l.get("behaviour-list.table-repute") }}
          </vs-th>
          <vs-th sort-key="is_published">{{
            $l.get("behaviour-list.table-is-published")
          }}</vs-th>
          <vs-th sort-key="relevance">{{
            $l.get("behaviour-list.table-relevance")
          }}</vs-th>
          <vs-th>{{ $l.get("behaviour-list.table-actions") }}</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td v-if="$l.isPtBr">{{ tr.summaryPtBr }}</vs-td>
            <vs-td v-if="$l.isEnUs">{{ tr.summaryEnUs }}</vs-td>
            <vs-td v-if="$l.isEsEs">{{ tr.summaryEsEs }}</vs-td>
            <vs-td v-if="tr.genoset">{{ tr.genoset.name }}</vs-td>
            <vs-td v-else>-</vs-td>
            <vs-td v-if="tr.genotype"
              >{{ tr.genotype.snp.rsid }}({{ tr.genotype.alleles }})</vs-td
            >
            <vs-td v-else>-</vs-td>
            <vs-td :data="tr.magnitude">{{ tr.magnitude }}</vs-td>
            <vs-td v-if="tr.repute == -1">
              <feather-icon icon="ThumbsDownIcon" svgClasses="text-danger" />
            </vs-td>
            <vs-td v-if="tr.repute == 1">
              <feather-icon icon="ThumbsUpIcon" svgClasses="text-success" />
            </vs-td>
            <vs-td v-if="tr.repute == 0">
              <!-- <feather-icon icon="HelpCircleIcon" /> -->
            </vs-td>
            <vs-td v-if="tr.isPublished">
              <feather-icon icon="CheckIcon" svgClasses="text-success" />
            </vs-td>
            <vs-td v-if="!tr.isPublished">
              <feather-icon icon="XIcon" svgClasses="text-danger" />
            </vs-td>
            <vs-td>{{ tr.relevance }}</vs-td>
            <vs-td>
              <vs-button
                color="primary"
                type="border"
                icon="edit"
                @click="onBehaviourEditClick(tr)"
              ></vs-button>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div style="margin-top: 20px; text-align: center">
        <vs-pagination
          :total="Math.ceil(data.behavioursPage.count / 25)"
          v-model="page"
          v-if="data"
        ></vs-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import SelectCreateInteractor from "@/business/behaviour-list/select-create";
import SelectBehaviourEditInteractor from "@/business/behaviour-list/select-behaviour-edit";
import ChangeFilterInteractor from "@/business/behaviour-list/change-filter";
import InitBehaviourListInteractor from "@/business/behaviour-list/init-behaviour-list-screen";
import BehaviourListScreenController from "@/adapters/controllers/screen-behaviour-list";
import Loader from "@/application/components/Loader";

export default {
  screen_name: "behaviour-list",
  data() {
    return {
      controller: null,
      interactors: {
        selectCreate: null,
        selectBehaviourEdit: null,
        changeFilter: null,
        initBehaviourListScreen: null
      },
      data: null,
      isLoading: false,
      page: 1,
      search: "",
      sort: "",
      searchTimer: null
    };
  },
  components: { Loader },
  beforeMount() {
    this.controller = this.$injector.get(BehaviourListScreenController);

    //{ INTERACTORS
    this.interactors.initBehaviourListScreen = this.$injector.get(
      InitBehaviourListInteractor
    );
    this.interactors.changeFilter = this.$injector.get(ChangeFilterInteractor);
    this.interactors.selectBehaviourEdit = this.$injector.get(
      SelectBehaviourEditInteractor
    );
    this.interactors.selectCreate = this.$injector.get(SelectCreateInteractor);
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
  },
  mounted() {
    this.interactors.initBehaviourListScreen.handle();
  },
  watch: {
    page: function() {
      this.interactors.changeFilter.handle(this.page, this.search, this.sort);
    }
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    handleSearch(searching) {
      this.search = searching;
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }
      const _ = this;
      this.searchTimer = setTimeout(() => {
        _.searchTimer = null;
        if (_.page != 1) {
          _.page = 1; // watch force update
        } else {
          _.interactors.changeFilter.handle(_.page, _.search, _.sort);
        }
        //
      }, 500);
    },
    handleSort(key, active) {
      if (active) {
        const prefix = active == "desc" ? "-" : "";
        this.sort = `${prefix}${key}`;
      } else {
        this.sort = "";
      }
      this.interactors.changeFilter.handle(this.page, this.search, this.sort);
    },
    onBehaviourEditClick(data) {
      this.interactors.selectBehaviourEdit.handle(data);
    },
    onCreateClick() {
      this.interactors.selectCreate.handle();
    }
  }
};
</script>

<style lang="scss"></style>
